
import storeCode from '~/mixins/storeCode'

export default {
  name: 'BaseBreadcrumbs',

  mixins: [
    storeCode,
  ],

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  computed: {
    last () {
      return [...this.items].pop()
    },
  },

  methods: {
    createUrl (url) {
      return this.localePath(url)
    },
  },

  jsonld () {
    const breadcrumbs = this.items.map((item, index) => {
      if (item.to) {
        return {
          '@type': 'ListItem',
          position: index,
          item: {
            '@id': `${this.$config.appUrl}${item.to}`,
            name: item.text,
          },
        }
      } else {
        return {
          '@type': 'ListItem',
          position: index,
          item: {
            name: item.text,
          },
        }
      }
    })

    breadcrumbs.shift()

    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbs,
    }
  },
}
